export default {
  'Maek Chi Gi: English Pressure Points': [
    {q: 'English pressure point for Maek Chi Gi 1?', a: 'Broken Sequence'},
    {
      q: 'English pressure point for Maek Chi Gi 2?',
      a: 'Arms Three Measures',
    },
    {q: 'English pressure point for Maek Chi Gi 3?', a: 'Heavens Residence'},
    {q: 'English pressure point for Maek Chi Gi 4?', a: 'Summits Spring'},
    {q: 'English pressure point for Maek Chi Gi 5?', a: 'Not on a meridian'},
    {q: 'English pressure point for Maek Chi Gi 6?', a: 'Upper Hinge'},
    {q: 'English pressure point for Maek Chi Gi 7?', a: 'Not on a meridian'},
    {q: 'English pressure point for Maek Chi Gi 8?', a: 'Big Wrapping'},
    {
      q: 'English pressure point for Maek Chi Gi 9?',
      a: 'Sea of Ki/Ums Junction',
    },
    {q: 'English pressure point for Maek Chi Gi 10?', a: 'Doves Tail'},
    {q: 'English pressure point for Maek Chi Gi 11?', a: 'Modesty Spring'},
    {q: 'English pressure point for Maek Chi Gi 12?', a: 'Systems Door'},
    {q: 'English pressure point for Maek Chi Gi 13?', a: 'Door of Awareness'},
    {q: 'English pressure point for Maek Chi Gi 14?', a: 'Spirit Yard'},
    {
      q: 'English pressure point for Maek Chi Gi 15?',
      a: 'Breasts Root/Heavens Pool',
    },
  ],
  'Maek Chi Gi: Korean Pressure Points': [
    {q: 'Korean pressure point for Maek Chi Gi 1?', a: 'Yuhl Guhl'},
    {q: 'Korean pressure point for Maek Chi Gi 2?', a: 'Soo Sahm Ri'},
    {q: 'Korean pressure point for Maek Chi Gi 3?', a: 'Chung Boo'},
    {q: 'Korean pressure point for Maek Chi Gi 4?', a: 'Geuk Chuhn'},
    {q: 'Korean pressure point for Maek Chi Gi 5?', a: 'Hohn Soo'},
    {q: 'Korean pressure point for Maek Chi Gi 6?', a: 'Gaek Joo In'},
    {q: 'Korean pressure point for Maek Chi Gi 7?', a: 'Hohn Soo'},
    {q: 'Korean pressure point for Maek Chi Gi 8?', a: 'Dae Po'},
    {q: 'Korean pressure point for Maek Chi Gi 9?', a: 'Ki Hae/Um Kyo'},
    {q: 'Korean pressure point for Maek Chi Gi 10?', a: 'Goo Mi'},
    {q: 'Korean pressure point for Maek Chi Gi 11?', a: 'Yuhm Chuhn'},
    {q: 'Korean pressure point for Maek Chi Gi 12?', a: 'Jahng Moon'},
    {q: 'Korean pressure point for Maek Chi Gi 13?', a: 'Ah Moon'},
    {q: 'Korean pressure point for Maek Chi Gi 14?', a: 'Shin Juhng'},
    {q: 'Korean pressure point for Maek Chi Gi 15?', a: 'Yoo Geun/Choong Ji'},
  ],
  'Maek Chi Gi: Meridians': [
    {q: 'Meridian point for Maek Chi Gi 1?', a: 'Lung 7'},
    {q: 'Meridian point for Maek Chi Gi 2?', a: 'Large Intestine 10'},
    {q: 'Meridian point for Maek Chi Gi 3?', a: 'Lung 3'},
    {q: 'Meridian point for Maek Chi Gi 4?', a: 'Heart 1'},
    {q: 'Meridian point for Maek Chi Gi 5?', a: 'Not on a meridian'},
    {q: 'Meridian point for Maek Chi Gi 6?', a: 'Gall Bladder 3'},
    {q: 'Meridian point for Maek Chi Gi 7?', a: 'Not on a meridian'},
    {q: 'Meridian point for Maek Chi Gi 8?', a: 'Spleen 21'},
    {q: 'Meridian point for Maek Chi Gi 9?', a: 'Conception 6/7'},
    {q: 'Meridian point for Maek Chi Gi 10?', a: 'Conception 15'},
    {q: 'Meridian point for Maek Chi Gi 11?', a: 'Conception 23'},
    {q: 'Meridian point for Maek Chi Gi 12?', a: 'Liver 13'},
    {q: 'Meridian point for Maek Chi Gi 13?', a: 'Governing 15'},
    {q: 'Meridian point for Maek Chi Gi 14?', a: 'Governing 24'},
    {q: 'Meridian point for Maek Chi Gi 15?', a: 'Stomach 18/Pericardium 1'},
  ],
  'Maek Cha Gi: English Pressure Points': [
    {q: 'English pressure point for Maek Cha Gi 1?', a: 'Three Um Junction'},
    {q: 'English pressure point for Maek Cha Gi 2?', a: 'Eyes of the Knee'},
    {q: 'English pressure point for Maek Cha Gi 3?', a: 'Bend Middle'},
    {q: 'English pressure point for Maek Cha Gi 4?', a: 'Sea of Blood'},
    {q: 'English pressure point for Maek Cha Gi 5?', a: 'Systems Door'},
    {q: 'English pressure point for Maek Cha Gi 6?', a: 'Summits Spring'},
    {q: 'English pressure point for Maek Cha Gi 7?', a: 'Sea of Ki'},
    {q: 'English pressure point for Maek Cha Gi 8?', a: 'Doves Tail'},
    {q: 'English pressure point for Maek Cha Gi 9?', a: 'Modesty Spring'},
    {q: 'English pressure point for Maek Cha Gi 10?', a: 'Not on a meridian'},
    {
      q: 'English pressure point for Maek Cha Gi 11?',
      a: 'Breasts Root/Heavens Pool',
    },
    {q: 'English pressure point for Maek Cha Gi 12?', a: 'Door of Awareness'},
    {q: 'English pressure point for Maek Cha Gi 13?', a: 'Upper Hinge'},
    {q: 'English pressure point for Maek Cha Gi 14?', a: 'Big Vertebrae'},
    {q: 'English pressure point for Maek Cha Gi 15?', a: 'Sunny Hill Spring'},
  ],
  'Maek Cha Gi: Korean Pressure Points': [
    {q: 'Korean pressure point for Maek Cha Gi 1?', a: 'Sahm Um Kyo'},
    {q: 'Korean pressure point for Maek Cha Gi 2?', a: 'Dohk Bi'},
    {q: 'Korean pressure point for Maek Cha Gi 3?', a: 'Wee Joong'},
    {q: 'Korean pressure point for Maek Cha Gi 4?', a: 'Hyul Hae'},
    {q: 'Korean pressure point for Maek Cha Gi 5?', a: 'Jahng Moon'},
    {q: 'Korean pressure point for Maek Cha Gi 6?', a: 'Geuk Chuhn'},
    {q: 'Korean pressure point for Maek Cha Gi 7?', a: 'Ki Hae'},
    {q: 'Korean pressure point for Maek Cha Gi 8?', a: 'Goo Mi'},
    {q: 'Korean pressure point for Maek Cha Gi 9?', a: 'Yuhm Chuhn'},
    {q: 'Korean pressure point for Maek Cha Gi 10?', a: 'Hohn Soo'},
    {q: 'Korean pressure point for Maek Cha Gi 11?', a: 'Yoo Geun/Choong Ji'},
    {q: 'Korean pressure point for Maek Cha Gi 12?', a: 'Ah Moon'},
    {q: 'Korean pressure point for Maek Cha Gi 13?', a: 'Gaek Joo In'},
    {q: 'Korean pressure point for Maek Cha Gi 14?', a: 'Dae Choo'},
    {q: 'Korean pressure point for Maek Cha Gi 15?', a: 'Yahng Neung Chuhn'},
  ],
  'Maek Cha Gi: Meridians': [
    {q: 'Meridian point for Maek Cha Gi 1?', a: 'Spleen 6'},
    {q: 'Meridian point for Maek Cha Gi 2?', a: 'Stomach 35'},
    {q: 'Meridian point for Maek Cha Gi 3?', a: 'Bladder 54'},
    {q: 'Meridian point for Maek Cha Gi 4?', a: 'Spleen 10'},
    {q: 'Meridian point for Maek Cha Gi 5?', a: 'Liver 13'},
    {q: 'Meridian point for Maek Cha Gi 6?', a: 'Heart 1'},
    {q: 'Meridian point for Maek Cha Gi 7?', a: 'Conception 8'},
    {q: 'Meridian point for Maek Cha Gi 8?', a: 'Conception 15'},
    {q: 'Meridian point for Maek Cha Gi 9?', a: 'Conception 23'},
    {q: 'Meridian point for Maek Cha Gi 10?', a: 'Not on a meridian'},
    {q: 'Meridian point for Maek Cha Gi 11?', a: 'Stomach 18/Pericardium 1'},
    {q: 'Meridian point for Maek Cha Gi 12?', a: 'Governing 15'},
    {q: 'Meridian point for Maek Cha Gi 13?', a: 'Gall Bladder 3'},
    {q: 'Meridian point for Maek Cha Gi 14?', a: 'Governing 14'},
    {q: 'Meridian point for Maek Cha Gi 15?', a: 'Gall Bladder 34'},
  ],
  'Jee Ahp Sool: English Pressure Points': [
    {
      q: 'English pressure point for Jee Ahp Sool - left hand point?',
      a: 'Union Valley',
    },
    {q: 'English pressure point for Jee Ahp Sool 1?', a: 'Yahngs Creek'},
    {
      q: 'English pressure point for Jee Ahp Sool 2?',
      a: 'Arms Three Measures',
    },
    {q: 'English pressure point for Jee Ahp Sool 3?', a: 'Heavens Residence'},
    {q: 'English pressure point for Jee Ahp Sool 4?', a: 'Empty Basin'},
    {q: 'English pressure point for Jee Ahp Sool 5?', a: 'Heavens Pillar'},
    {q: 'English pressure point for Jee Ahp Sool 6?', a: 'Pupil Seam'},
    {q: 'English pressure point for Jee Ahp Sool 7?', a: 'Mans Center'},
    {q: 'English pressure point for Jee Ahp Sool 8?', a: 'Summits Spring'},
    {q: 'English pressure point for Jee Ahp Sool 9?', a: 'Surging Ki'},
    {q: 'English pressure point for Jee Ahp Sool 10?', a: 'Bend Middle'},
  ],
  'Jee Ahp Sool: Korean Pressure Points': [
    {
      q: 'Korean pressure point for Jee Ahp Sool - left hand point?',
      a: 'Hahp Gohk',
    },
    {q: 'Korean pressure point for Jee Ahp Sool 1?', a: 'Yahng Gyea'},
    {q: 'Korean pressure point for Jee Ahp Sool 2?', a: 'Soo Sahm Ri'},
    {q: 'Korean pressure point for Jee Ahp Sool 3?', a: 'Chung Boo'},
    {q: 'Korean pressure point for Jee Ahp Sool 4?', a: 'Gyul Bun'},
    {q: 'Korean pressure point for Jee Ahp Sool 5?', a: 'Chuhn Joo'},
    {q: 'Korean pressure point for Jee Ahp Sool 6?', a: 'Dohng Jah Ryoh'},
    {q: 'Korean pressure point for Jee Ahp Sool 7?', a: 'In Joong'},
    {q: 'Korean pressure point for Jee Ahp Sool 8?', a: 'Geuk Chuhn'},
    {q: 'Korean pressure point for Jee Ahp Sool 9?', a: 'Ki Choong'},
    {q: 'Korean pressure point for Jee Ahp Sool 10?', a: 'Wee Joong'},
  ],
  'Jee Ahp Sool: Meridians': [
    {
      q: 'Meridian point for Jee Ahp Sool - left hand point?',
      a: 'Large Intestine 4',
    },
    {q: 'Meridian point for Jee Ahp Sool 1?', a: 'Large Intestine 5'},
    {q: 'Meridian point for Jee Ahp Sool 2?', a: 'Large Intestine 10'},
    {q: 'Meridian point for Jee Ahp Sool 3?', a: 'Lung 3'},
    {q: 'Meridian point for Jee Ahp Sool 4?', a: 'Stomach 12'},
    {q: 'Meridian point for Jee Ahp Sool 5?', a: 'Bladder 10'},
    {q: 'Meridian point for Jee Ahp Sool 6?', a: 'Gall Bladder 1'},
    {q: 'Meridian point for Jee Ahp Sool 7?', a: 'Governing 26'},
    {q: 'Meridian point for Jee Ahp Sool 8?', a: 'Heart 1'},
    {q: 'Meridian point for Jee Ahp Sool 9?', a: 'Stomach 30'},
    {q: 'Meridian point for Jee Ahp Sool 10?', a: 'Bladder 40/54'},
  ],
};
